<template>
  <div>
    <div class="row">
      <div class="col-12">

        <div class="upload-input my-1">
          <label class="d-block">{{ $t('Select') }} {{ $t(`${fileName}`) }}</label>
          <input id="uploadFile" ref="file" type="file" accept="image/*,video/mp4" class="upload-file-input d-none"
            @change="selectFile">

          <b-button ref="uploadBtn" squared variant="outline-secondary upload-file-btn" @click="upload">
            {{ $t('upload') }}
          </b-button>

          <label class="w-70 file-name btn btn-outline-secondary rounded-0 mb-0" @click="selectFileToUpload">{{
            filename.length ? (filename.length > 20 ? '...' + filename.slice(filename.length - 20, filename.length) :
              filename) : this.$t('Select File') }}</label>

        </div>
        <div v-if="currentFile" class="progress mt-1">
          <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar" :aria-valuenow="progress"
            aria-valuemin="0" aria-valuemax="100" :style="{ width: progress + '%' }">
            {{ progress }}%
          </div>
        </div>

        <div class="col-12">
          <span v-if="validFileType" class="text-danger">Invalid File type allowedExtensions: jpg|jpeg|png|gif|mp4 </span>
          <span v-if="validFileSize" class="text-danger">Invalid File Size File Size Should Be Less than 40 Mb</span>
          <div v-if="message" class="alert alert-light" role="alert">

            <span v-if="message.fileId">The File Uploaded Successfully</span>
            <span v-if="message && !message.fileId" class="text-danger">{{ message }}</span>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import UploadService from './UploadFilesService'

export default {
  name: 'UploadFiles',
  props: ['userName', 'companyId', 'fileName'],
  data() {
    return {
      filename: '',
      selectedFiles: undefined,
      currentFile: undefined,
      progress: 0,
      message: '',
      validFileType: false,
      validFileSize: false,

      fileInfos: [],
    }
  },
  mounted() {

  },
  methods: {


    selectFileToUpload() {
      this.$refs.file.click()
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },

    async selectFile({ target: { files } }) {
      this.validFileType = false
      this.validFileSize = false
      this.message = ''
      const size = (files[0].size / 1024 / 1024).toFixed(2);
      const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.mp4)$/i;



      if (!allowedExtensions.exec(files[0].name)) {
        // alert('Invalid file type');
        this.validFileType = true
        this.selectedFiles = undefined
        this.filename = ''
        this.currentFile = undefined

        return false;
      } else {
        if (size > 40 || size < 0.01) {
          // alert("File must be less than 40 MB", size);
          this.validFileSize = true
        } else {
          this.selectedFiles = this.$refs.file.files
          this.filename = this.$refs.file.files[0].name || ''
          this.currentFile = undefined
          this.message = ''
        }
      }
    },

    returnFileSize(number) {
      if (number < 1024) {
        return number + 'bytes';
      } else if (number >= 1024 && number < 1048576) {
        return (number / 1024).toFixed(2) + 'KB';
      } else if (number >= 1048576) {
        return (number / 1048576).toFixed(2) + 'MB';
      }
    }
    ,
    upload() {
      this.progress = 0

      this.currentFile = this.selectedFiles.item(0)
      UploadService.upload(this.currentFile, event => {
        this.progress = Math.round((100 * event.loaded) / event.total)
      }, this.companyId, this.userName)
        .then(response => {
          this.message = response.data
          this.$emit('msg', this.message)
        })
        .catch(() => {
          this.progress = 0
          this.message = 'Could not upload the file!'
          this.currentFile = undefined
        }).finally(() => {
          if (!this.message) {
            this.message = 'Could not upload the file!'
          }
        })

      this.selectedFiles = undefined
    },
  },

}
</script>
<style scoped lang="scss">
.file-name {
  border-left-width: 0px !important;
}

.w-70 {
  width: 72% !important;
}
</style>
